import React, { useEffect, useState } from 'react';

import Filters from '../../components/Common/Filters';
import Loader from '../../components/Common/Loader';
import CustomTable from '../../components/Common/Table';
import ProjectList from '../../components/Repository/ProjectList';
import ProjectSearch from '../../components/Repository/ProjectSearch';
import './index.css'

import DownloadIcon from '@iconscout/react-unicons/icons/uil-download-alt'
import ListIcon from '@iconscout/react-unicons/icons/uil-list-ul'
import Dropdown from '../../components/Common/Dropdown';
import DownloadModal from '../../components/Modals/DownloadModal';
import { getLastDataUpdate, getProjectsList, sendMatomoEvent } from '../../data/dataService'

import { Pagination } from '@mantine/core';
import * as FileSaver from 'file-saver'
import XLSX from 'sheetjs-style'
import { formatWithSeparators } from '../../data/strings';

import { useTranslation } from 'react-i18next';
import i18next from 'i18next';
import { NavLink } from 'react-router-dom';

const Repository = () => {
    const { t, i18n } = useTranslation()

    const [loading, setLoading] = useState(true);
    const [listView, setListView] = useState(true);
    const [currentPage, setCurrentPage] = React.useState(1);
    const [resultsPerPage, setResultsPerPage] = React.useState(25);
    const [order, setOrder] = React.useState(t('repository.per_date'));
    const [showDownloadModal, setShowDownloadModal] = useState(false);

    const [data, setData] = useState([]);

    const [fromYear, setFromYear] = useState(2021);
    const [toYear, setToYear] = useState(2027);
    const [sistemiFilter, setSistemiFilter] = useState([]);
    const [ambitiFilter, setAmbitiFilter] = useState([]);
    const [programmiFilter, setProgrammiFilter] = useState([]);
    const [azioniFilter, setAzioniFilter] = useState([]);
    const [tipiAzioneFilter, setTipiAzioneFilter] = useState([]);
    const [beneficiariFilter, setBeneficiariFilter] = useState([]);
    const [tipiBeneficiarioFilter, setTipiBeneficiarioFilter] = useState([]);
    const [provinceFilter, setProvinceFilter] = useState([]);
    const [callsFilter, setCallsFilter] = useState([]);

    const [keywords, setKeywords] = useState([]);
    const [allKeywordsRequired, setAllKeywordsRequired] = useState(false);
    const [allBeneficiariRequired, setAllBeneficiariRequired] = useState(false);

    const [filtersMap, setFiltersMap] = useState({})

    useEffect(() => {
        document.title = "Repository - Monitoraggio S3 Emilia-Romagna"
        window.scrollTo(0, 0)
        getProjects()
    }, [])

    // add event listener on fromYear
    useEffect(() => {
        getProjects()
        setCurrentPage(1);
        window.scrollTo(0, 0);
    }, [order, fromYear, toYear, keywords, allKeywordsRequired, allBeneficiariRequired,
        sistemiFilter, ambitiFilter, programmiFilter, azioniFilter, tipiAzioneFilter, beneficiariFilter, provinceFilter, callsFilter, tipiBeneficiarioFilter])

    // Used to keep the filters to 0 when nothing is there
    const [allFiltersGot, setAllFiltersGot] = useState(false);
    const [allSistemi, setAllSistemi] = useState([]);
    const [allAmbiti, setAllAmbiti] = useState([]);
    const [allAzioni, setAllAzioni] = useState([]);
    const [allTipiAzione, setAllTipiAzione] = useState([]);
    const [allProgrammi, setAllProgrammi] = useState([]);
    const [allProvince, setAllProvince] = useState([]);
    const [allCalls, setAllCalls] = useState([]);
    const [allBeneficiari, setAllBeneficiari] = useState([]);
    const [allTipiBeneficiario, setAllTipiBeneficiario] = useState([]);

    const [filtersChanged, setFiltersChanged] = useState({
        sistemi: false,
        ambiti: false,
        azioni: false,
        tipiAzione: false,
        programma: false,
        province: false,
        calls: false,
        beneficiari: false,
        tipiBeneficiario: false
    })

    function changeFilters(key) {
        Object.keys(filtersChanged).forEach(key => {
            filtersChanged[key] = false
        })
        filtersChanged[key] = true

        setFiltersChanged(filtersChanged)
    }


    const populateFiltersMap = (projects) => {

        // console.log('populateFiltersMap', filtersChanged)
        var newFiltersMap = structuredClone(filtersMap)
        if (!allFiltersGot) {
            // console.log("NOT ALL FILTERS GOT")
            setAllSistemi([...new Set(projects.map(x => x.sistemi).flat())])
            setAllAmbiti([...new Set(projects.map(x => x.settori).flat())])
            setAllAzioni([...new Set(projects.map(x => x.azioni).flat().map(x => x.name))])
            setAllTipiAzione([...new Set(projects.map(x => x.azioni).flat().map(x => x.type))])
            setAllProgrammi([...new Set(projects.map(x => x.programma))])
            setAllProvince([...new Set(projects.map(x => x.province).flat())])
            setAllCalls([...new Set(projects.map(x => x.call).filter(x => x != null))])
            // setAllBeneficiari([...new Set(projects.map(x => x.beneficiari).flat().map(x => x.name))])
            setAllTipiBeneficiario([...new Set(projects.map(x => x.beneficiari).flat().map(x => x.type))])
            setAllFiltersGot(true)
        }
        let tipiBeneficiarioProjs = {}

        // check the changed filters. if the key is false, bring the counter to 0
        Object.keys(filtersChanged).forEach(key => {
            if (!filtersChanged[key]) {
                if (key == 'sistemi') {
                    newFiltersMap.sistemi = {}
                    allSistemi.forEach(sistema => {
                        newFiltersMap.sistemi[sistema] = 0
                    })
                }
                else if (key == 'ambiti') {
                    newFiltersMap.ambiti = {}
                    allAmbiti.forEach(ambito => {
                        newFiltersMap.ambiti[ambito] = 0
                    })
                }
                else if (key == 'azioni') {
                    newFiltersMap.azioni = {}
                    allAzioni.forEach(azione => {
                        newFiltersMap.azioni[azione] = 0
                    })
                }
                else if (key == 'tipiAzione') {
                    newFiltersMap.tipiAzione = {}
                    allTipiAzione.forEach(tipoAzione => {
                        newFiltersMap.tipiAzione[tipoAzione] = 0
                    })
                }
                else if (key == 'programma') {
                    newFiltersMap.programma = {}
                    allProgrammi.forEach(programma => {
                        newFiltersMap.programma[programma] = 0
                    })
                }
                else if (key == 'province') {
                    newFiltersMap.province = {}
                    allProvince.forEach(provincia => {
                        newFiltersMap.province[provincia] = 0
                    })
                }
                else if (key == 'calls') {
                    newFiltersMap.call = {}
                    allCalls.forEach(call => {
                        newFiltersMap.call[call] = 0
                    })
                }
                else if (key == 'tipiBeneficiario') {
                    newFiltersMap.tipiBeneficiario = {}
                    allTipiBeneficiario.forEach(tipoBeneficiario => {
                        newFiltersMap.tipiBeneficiario[tipoBeneficiario] = 0
                    })
                }
            }
        })



        projects.forEach(project => {
            if (!filtersChanged['azioni']) {
                if (project["azioni"]) {
                    project["azioni"].forEach(element => {
                        if (newFiltersMap["azioni"] == undefined) { newFiltersMap["azioni"] = {} }
                        if (newFiltersMap["azioni"][element['name']] == undefined) { newFiltersMap["azioni"][element['name']] = 1 }
                        else { newFiltersMap["azioni"][element['name']] = newFiltersMap["azioni"][element['name']] + 1 }

                    });
                }
            }

            if (!filtersChanged['beneficiari']) {
                if (project["beneficiari"]) {
                    project["beneficiari"].forEach(element => {
                        if (newFiltersMap["beneficiari"] == undefined) { newFiltersMap["beneficiari"] = {} }
                        if ((element['isInRegion'])) {
                            if (newFiltersMap["beneficiari"][element['name']] == undefined) { newFiltersMap["beneficiari"][element['name']] = 1 }
                            else { newFiltersMap["beneficiari"][element['name']] = newFiltersMap["beneficiari"][element['name']] + 1 }
                        }
                    });
                }
            }

            if (!filtersChanged['programma']) {
                if (project["programma"]) {
                    if (newFiltersMap["programma"] == undefined) { newFiltersMap["programma"] = {} }
                    if (newFiltersMap["programma"][project["programma"]] == undefined) { newFiltersMap["programma"][project["programma"]] = 1 }
                    else { newFiltersMap["programma"][project["programma"]] = newFiltersMap["programma"][project["programma"]] + 1 }
                }
            }
            if (!filtersChanged['type']) {
                if (project["type"]) {
                    if (newFiltersMap["type"] == undefined) { newFiltersMap["type"] = {} }
                    if (newFiltersMap["type"][project["type"]] == undefined) { newFiltersMap["type"][project["type"]] = 1 }
                    else { newFiltersMap["type"][project["type"]] = newFiltersMap["type"][project["type"]] + 1 }
                }
            }

            if (!filtersChanged['sistemi']) {
                var key = "sistemi"

                if (project[key]) {
                    if (project[key].length > 0) {
                        project[key].forEach(element => {
                            if (newFiltersMap[key] == undefined) { newFiltersMap[key] = {} }

                            if (newFiltersMap[key][element] == undefined) { newFiltersMap[key][element] = 1 }
                            else { newFiltersMap[key][element] = newFiltersMap[key][element] + 1 }
                        })
                    }
                    // else increment key "Altro"
                    else {
                        if (newFiltersMap[key] == undefined) { newFiltersMap[key] = {} }
                        if (newFiltersMap[key]['Altro'] == undefined) { newFiltersMap[key]['Altro'] = 1 }
                        else { newFiltersMap[key]['Altro'] = newFiltersMap[key]['Altro'] + 1 }
                    }
                }
            }

            if (!filtersChanged['ambiti']) {
                var key = 'settori'
                var keyName = "ambiti"

                if (project[key]) {
                    if (project[key].length > 0) {
                        project[key].forEach(element => {
                            if (newFiltersMap[keyName] == undefined) { newFiltersMap[keyName] = {} }

                            if (newFiltersMap[keyName][element] == undefined) { newFiltersMap[keyName][element] = 1 }
                            else { newFiltersMap[keyName][element] = newFiltersMap[keyName][element] + 1 }
                        })
                    }
                    // else increment key "Altro"
                    else {
                        if (newFiltersMap[keyName] == undefined) { newFiltersMap[keyName] = {} }
                        if (newFiltersMap[keyName]['Altro'] == undefined) { newFiltersMap[keyName]['Altro'] = 1 }
                        else { newFiltersMap[keyName]['Altro'] = newFiltersMap[keyName]['Altro'] + 1 }
                    }
                }
            }

            // Calls
            if (!filtersChanged['calls']) {
                if (project['call']) {
                    if (newFiltersMap['call'] == undefined) { newFiltersMap['call'] = {} }
                    if (newFiltersMap['call'][project['call']] == undefined) { newFiltersMap['call'][project['call']] = 1 }
                    else { newFiltersMap['call'][project['call']] = newFiltersMap['call'][project['call']] + 1 }
                }
            }


            if (!filtersChanged['province']) {
                // Province
                let provinceAdded = {}
                project['beneficiari'] && project['beneficiari'].forEach(beneficiario => {
                    if (beneficiario['province'] && beneficiario['isInRegion']) {
                        let provincia = beneficiario['province'];

                        if (newFiltersMap['province'] == undefined) { newFiltersMap['province'] = {} }
                        if (newFiltersMap['province'][provincia] == undefined) { newFiltersMap['province'][provincia] = 1 }
                        else {
                            if (!provinceAdded[provincia])
                                newFiltersMap['province'][provincia] = newFiltersMap['province'][provincia] + 1
                        }

                        provinceAdded[provincia] = true
                    }
                })
            }


            if (!filtersChanged['beneficiari']) {
                // Tipi beneficiario
                project['beneficiari'] && project['beneficiari'].forEach(beneficiario => {
                    if (beneficiario['type'] && beneficiario['type']['label']) {
                        let code = beneficiario['type']['label'];

                        if (!tipiBeneficiarioProjs[code]) tipiBeneficiarioProjs[code] = []
                        tipiBeneficiarioProjs[code].push(project.id)

                        if (newFiltersMap['tipiBeneficiario'] == undefined) { newFiltersMap['tipiBeneficiario'] = {} }
                        if (newFiltersMap['tipiBeneficiario'][code] == undefined) { newFiltersMap['tipiBeneficiario'][code] = 1 }
                        else { newFiltersMap['tipiBeneficiario'][code] = newFiltersMap['tipiBeneficiario'][code] + 1 }
                    }
                })
            }


            if (!filtersChanged['tipiAzione']) {
                // Tipo azione
                project['azioni'] && project['azioni'].forEach(action => {
                    if (action['type']) {
                        let name = action['type'];
                        if (newFiltersMap['tipiAzione'] == undefined) { newFiltersMap['tipiAzione'] = {} }
                        if (newFiltersMap['tipiAzione'][name] == undefined) { newFiltersMap['tipiAzione'][name] = 1 }
                        else { newFiltersMap['tipiAzione'][name] = newFiltersMap['tipiAzione'][name] + 1 }
                    }
                })
            }
        });


        var possibleBeneficiaryLabels = ["Università/Ente di ricerca", "Altro", "Impresa", "Ente pubblico", "Fondazione ITS", "Ente di formazione"]

        // fill with 0 all possible labels
        possibleBeneficiaryLabels.forEach(label => {
            if (tipiBeneficiarioProjs[label] == undefined) { tipiBeneficiarioProjs[label] = [] }
        })

        // transform all arrays of tipiBeneficiarioProjs to set
        Object.keys(tipiBeneficiarioProjs).forEach(key => {
            if ((tipiBeneficiarioFilter && (tipiBeneficiarioFilter.includes(key) || tipiBeneficiarioFilter.length == 0)) || !tipiBeneficiarioFilter)
                tipiBeneficiarioProjs[key] = new Set(tipiBeneficiarioProjs[key]).size
            else
                tipiBeneficiarioProjs[key] = 0
        })

        newFiltersMap.tipiBeneficiario = tipiBeneficiarioProjs

        allSistemi.map((sistema) => { if (!newFiltersMap.sistemi) newFiltersMap.sistemi = {}; if (newFiltersMap.sistemi[sistema] == undefined) newFiltersMap.sistemi[sistema] = 0 })
        allAmbiti.map((ambito) => { if (!newFiltersMap.ambiti) newFiltersMap.ambiti = {}; if (newFiltersMap.ambiti[ambito] == undefined) newFiltersMap.ambiti[ambito] = 0 })
        allAzioni.map((azione) => { if (!newFiltersMap.azioni) newFiltersMap.azioni = {}; if (newFiltersMap.azioni[azione] == undefined) newFiltersMap.azioni[azione] = 0 })
        allTipiAzione.map((azione) => { if (!newFiltersMap.tipiAzione) newFiltersMap.tipiAzione = {}; if (newFiltersMap.tipiAzione[azione] == undefined) newFiltersMap.tipiAzione[azione] = 0 })
        allProgrammi.map((program) => { if (!newFiltersMap.programma) newFiltersMap.programma = {}; if (newFiltersMap.programma[program] == undefined) newFiltersMap.programma[program] = 0 })
        allProvince.map((provincia) => { if (!newFiltersMap.province) newFiltersMap.province = {}; if (newFiltersMap.province[provincia] == undefined) newFiltersMap.province[provincia] = 0 })
        allCalls.map((call) => { if (!newFiltersMap.call) newFiltersMap.call = {}; if (newFiltersMap.call[call] == undefined) newFiltersMap.call[call] = 0 })

        // remove "null" from allCalls
        if (newFiltersMap.call && newFiltersMap.call['null']) delete newFiltersMap.call['null']

        // remove from newFiltersMap.province the ones with less than 3 projects
        Object.keys(newFiltersMap.province).forEach(key => {
            if (newFiltersMap.province[key] < 3 || key == 'n.a.' || key == "Firenze") {
                delete newFiltersMap.province[key]
            }
        })

        setFiltersMap(newFiltersMap)
    }

    async function getProjects() {
        setLoading(true)

        // ? Remove after the backend fix
        // check the url is present the ?id query
        const urlParams = new URLSearchParams(window.location.search);
        const id = urlParams.get('id');
        // ? End Remove after the backend fix


        var projects = [], beneficiari = [];
        var projectsResponse = await getProjectsList({
            sortedByYear: order == t('repository.per_date'),
            keywords: keywords,
            allKeywordsRequired: allKeywordsRequired,
            fromYear: id ? 2014 : fromYear,
            toYear: id ? 2023 : toYear,
            program: programmiFilter,
            sistemi: sistemiFilter,
            settori: ambitiFilter,
            actionType: tipiAzioneFilter,
            beneficiaryNames: beneficiariFilter,
            allBeneficiariRequired: allBeneficiariRequired,
            // beneficiaryTypeCodes: [tipiBeneficiarioFilter],
            beneficiaryTypeNames: tipiBeneficiarioFilter,
            provinces: provinceFilter,
            calls: callsFilter,
        })
        if (projectsResponse) {
            projects = projectsResponse.projects;
            beneficiari = projectsResponse.beneficiari;

            // ? Remove after the backend fix
            // console.log('projects', projects.filter(x => x.azioni.length > 1))
            if (id) {
                projects = projects.filter(project => project.id == id)
            }
            // ? End Remove after the backend fix


            setLoading(false)
            setData(projects)



            // set allBeneficiari from beneficiari list with somethign like {beneficiario1: 0, beneficiario2: 0, ...}
            //
            var beneficiariMap = {}
            beneficiari.forEach(beneficiario => {
                beneficiariMap[beneficiario] = 0
            })
            setAllBeneficiari(beneficiariMap)
            populateFiltersMap(projects)
        }
    }


    function downloadDocuments(type, selectedColumns, possibleColumnsObj) {
        console.log('downloadDocuments', type, selectedColumns, possibleColumnsObj)
        const fileType = type == 'excel' ? 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8' : 'application/csv;charset=UTF-8'
        const fileExtension = type == 'excel' ? '.xlsx' : '.csv'
        const joinSeparator = " | "

        let documentsListClean = structuredClone(data)
        documentsListClean = documentsListClean.map(x => {
            // console.log("X", JSON.parse(JSON.stringify(x))) 
            // if key is not in selectedColumns, remove
            x.sistemi = x.sistemi.join(joinSeparator)
            x.settori = x.settori.join(joinSeparator)
            x.mainTypeOfAction = x.azioni.map(x => x.type).join(joinSeparator)
            x.azioni = x.azioni.map(x => x.name).join(joinSeparator)
            x.beneficiariRegionali = x.beneficiari.filter(x => x.isInRegion == true).map(x => x.name).join(joinSeparator)
            x.beneficiariNonRegionali = x.beneficiari.filter(x => x.isInRegion == false).map(x => x.name).join(joinSeparator)

            let provinces = x['beneficiari'].filter(beneficiario => beneficiario.isInRegion == true).map(beneficiario => beneficiario.province).join(joinSeparator)
            x.province = [...new Set(provinces.split(joinSeparator))].join(joinSeparator)

            let municipalities = x['beneficiari'].filter(beneficiario => beneficiario.isInRegion == true).map(beneficiario => beneficiario.city).join(joinSeparator)
            x.municipality = [...new Set(municipalities.split(joinSeparator))].join(joinSeparator)


            x.keywords = x.keywords.join(joinSeparator)
            try {
                x.numPeopleTrained = x.outputIndicators['numPeopleTrained'] != 0 ? x.outputIndicators['numPeopleTrained'] : ''
            }
            catch (error) {
                x.numPeopleTrained = ''
            }

            Object.keys(x).forEach(key => {
                if (!selectedColumns.includes(key)) {
                    delete x[key]
                }
            })

            // change name of the key based on possibleColumnsObj label. The key is the key and the label is the new key
            // order based on order property in possibleColumnsObj 
            let ordered = {}
            Object.keys(possibleColumnsObj).forEach(key => {
                if (x[key]) {
                    ordered[key] = x[key]
                    ordered[possibleColumnsObj[key].label] = x[key]
                    delete ordered[key]
                }
            })
            x = ordered

            return x
        })


        let filtersRow = {}

        let appliedFiltersString = ''
        if (fromYear || toYear) appliedFiltersString += `Anno: ${fromYear}-${toYear}; `
        if (sistemiFilter.length > 0) appliedFiltersString += `Sistemi: ${sistemiFilter.join(', ')}; `
        if (ambitiFilter.length > 0) appliedFiltersString += `Settori: ${ambitiFilter.join(', ')}; `
        if (programmiFilter) appliedFiltersString += `Programma: ${programmiFilter}; `
        if (azioniFilter) appliedFiltersString += `Azione: ${azioniFilter}; `
        if (tipiAzioneFilter) appliedFiltersString += `Tipo azione: ${tipiAzioneFilter}; `
        if (beneficiariFilter.length > 0) appliedFiltersString += `Beneficiari: ${beneficiariFilter.join(', ')}; `
        if (tipiBeneficiarioFilter.length > 0) appliedFiltersString += `Tipo beneficiario: ${tipiBeneficiarioFilter.join(', ')}; `
        if (provinceFilter.length > 0) appliedFiltersString += `Province: ${provinceFilter.join(', ')}; `
        if (callsFilter.length > 0) appliedFiltersString += `Calls: ${callsFilter.join(', ')}; `
        if (keywords.length > 0) appliedFiltersString += `Keywords: ${keywords.join(', ')}; `
        if (allKeywordsRequired) appliedFiltersString += `Tutte le keywords; `
        if (allBeneficiariRequired) appliedFiltersString += `Tutti i beneficiari; `
        filtersRow["Applied filters"] = appliedFiltersString

        documentsListClean.unshift(filtersRow)


        const ws = XLSX.utils.json_to_sheet(documentsListClean)
        const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] }
        const buffer = XLSX.write(wb, { bookType: type == 'excel' ? 'xlsx' : 'csv', type: 'array' })
        const blobData = new Blob([buffer], { type: fileType })
        let currentDateTimeFormatted = new Date();
        // format like 2023-11-30 HH:MM
        currentDateTimeFormatted = currentDateTimeFormatted.getFullYear() + '-' + (currentDateTimeFormatted.getMonth() + 1) + '-' + currentDateTimeFormatted.getDate() +
            ' ' + currentDateTimeFormatted.getHours() + ':' + currentDateTimeFormatted.getMinutes()

        FileSaver.saveAs(blobData, 'Download Repository Monitoraggio S3 - ' + currentDateTimeFormatted + fileExtension)
        sendMatomoEvent("download", "repository", "download " + type)

    }


    return (
        <div id="repository" className='container-fluid'>
            {data.length > 0 ? <DownloadModal
                show={showDownloadModal}
                exampleProject={data[0]}
                onChange={() => { setShowDownloadModal(!showDownloadModal) }}
                onDownload={(type, selectedColumns, possibleColumnsObj) => {
                    downloadDocuments(type, selectedColumns, possibleColumnsObj)
                }}
            /> : null}

            {Object.keys(filtersMap).length > 0 ? <div className='row justify-content-center mb-4'>
                <div className='col-12'>
                    <ProjectSearch
                        beneficiari={allBeneficiari}
                        selectedKeywords={keywords}
                        selectedBeneficiari={beneficiariFilter}
                        onKeywordsChange={(keywords) => {
                            setKeywords(keywords)
                        }}
                        onBeneficiariChange={(beneficiari, allBeneficiariRequired) => {
                            setBeneficiariFilter(beneficiari)
                            setAllBeneficiariRequired(allBeneficiariRequired)
                        }}
                        onSubmit={(keywords, allKeywordsRequired) => {
                            setKeywords(keywords)
                            setAllKeywordsRequired(allKeywordsRequired)
                        }}
                    />
                </div>
            </div> : <> </>}

            <div className='container-fluid px-md-5 px-0 pb-5 pt-2'>
                <div className='row justify-content-center'>
                    {Object.keys(filtersMap).length > 0 ? <div className='col-md-3 col-12 position-sticky'>
                        <Filters
                            loading={loading}
                            defaultYears={[fromYear, toYear]}
                            sistemi={filtersMap.sistemi}
                            ambiti={filtersMap.ambiti}
                            // beneficiario={filtersMap.beneficiari}
                            programma={filtersMap.programma}
                            azione={filtersMap.azioni}
                            tipiAzione={filtersMap.tipiAzione}
                            provincia={filtersMap.province}
                            calls={filtersMap.call}
                            tipiBeneficiario={filtersMap.tipiBeneficiario}

                            selectedDefaultYears={[fromYear, toYear]}
                            selectedSistemi={sistemiFilter}
                            selectedAmbiti={ambitiFilter}
                            selectedAzione={azioniFilter}
                            selectedProgramma={programmiFilter}
                            selectedProvincia={provinceFilter}
                            selectedCalls={callsFilter}
                            selectedBeneficiario={beneficiariFilter}
                            selectedTipiAzione={tipiAzioneFilter}
                            selectedTipiBeneficiario={tipiBeneficiarioFilter}

                            onClearFilters={async () => {
                                // await all the settings
                                await Promise.all([
                                    setKeywords([]),
                                    setSistemiFilter([]),
                                    setAmbitiFilter([]),
                                    setAzioniFilter([]),
                                    setProgrammiFilter([]),
                                    setProvinceFilter([]),
                                    setBeneficiariFilter([]),
                                    setTipiAzioneFilter([]),
                                    setTipiBeneficiarioFilter([]),
                                    setCallsFilter([])
                                ])

                            }}

                            onYearsChange={(value) => {
                                setFromYear(value[0])
                                setToYear(value[1])
                            }}
                            onSistemiChange={(value) => {
                                console.log("Area changed to", value)
                                setSistemiFilter(value)
                                changeFilters('sistemi')
                            }}
                            onAmbitiChange={(value) => {
                                console.log("Ambiti changed to", value)
                                setAmbitiFilter(value)
                                changeFilters('ambiti')
                            }}
                            onProgrammaChange={(value) => {
                                console.log("Programma changed to", value)
                                setProgrammiFilter(value)
                                changeFilters('programma')
                            }}
                            onAzioneChange={(value) => {
                                console.log("Azione changed to", value)
                                setAzioniFilter(value)
                                changeFilters('azioni')
                            }}
                            onTipiAzioneChange={(value) => {
                                console.log("Tipo changed to", value)
                                setTipiAzioneFilter(value)
                                changeFilters('tipiAzione')
                            }}
                            onTipiBeneficiarioChange={(value) => {
                                console.log("Tipo beneficiario changed to", value)
                                setTipiBeneficiarioFilter(value)
                                changeFilters('tipiBeneficiario')
                            }}
                            onBeneficiarioChange={(value) => {
                                console.log("Beneficiario changed to", value)
                                setBeneficiariFilter(value)
                                changeFilters('beneficiari')
                            }}
                            onProvinciaChange={(value) => {
                                console.log("Provincia changed to", value)
                                setProvinceFilter(value)
                                changeFilters('province')
                            }}
                            onCallsChange={(value) => {
                                console.log("Calls changed to", value)
                                setCallsFilter(value)
                                changeFilters('calls')
                            }}
                            className='' />

                    </div> : <></>}

                    <div className='col-md-9 col-12 mt-md-0 mt-4'>
                        {loading ? <Loader size={'xl'} /> : <div className='repository-content px-md-5'>
                            {/* MOVE TO COMPONENT */}
                            {data.length > 0 ?
                                <div>
                                    <div className='d-flex flex-column flex-lg-row justify-content-between text-start mb-4'>
                                        <div className='my-auto'>

                                            <span className='text-start'>
                                                {i18next.t('repository.page_info', { nDocs: formatWithSeparators(data.length, 0), currentPage: currentPage, of: formatWithSeparators(Math.ceil(data.length / resultsPerPage), 0) })}
                                            </span>
                                        </div>
                                        <div className='d-flex flex-column flex-lg-row my-auto'>
                                            <button className='btn bg-white d-flex mt-lg-0 mt-2 border-0'
                                                onClick={() => setShowDownloadModal(true)}
                                            >
                                                <DownloadIcon size={15} className='my-auto  me-2' />
                                                <span className='my-auto'>Download</span>
                                            </button>
                                            <button
                                                onClick={() => setListView(!listView)}
                                                className='btn bg-white d-flex ms-lg-2 mt-lg-0 mt-2 text-start border-0'>
                                                <ListIcon size={15} className='my-auto me-2' />
                                                <span className='my-auto'>{listView ? t('repository.table') : t('repository.list')}</span>
                                            </button>
                                            <button className='border-0 bg-transparent px-0 mx-0'>
                                                <Dropdown
                                                    className='ms-lg-2 mt-lg-0 mt-2 text-start'
                                                    title={resultsPerPage + ' ' + t('repository.results_per_page')}
                                                    description={t('repository.num_results')}
                                                    elements={['25', '50', '100', '200']}
                                                    selected={resultsPerPage}
                                                    onClick={(element) => { setResultsPerPage(element) }}
                                                />
                                            </button>
                                            <button className='border-0 bg-transparent px-0 mx-0'>
                                                <Dropdown
                                                    className='ms-lg-2 mt-lg-0 mt-2 text-start'
                                                    title={t('repository.order') + ' ' + order.toLowerCase()}
                                                    elements={[t('repository.per_date'), t('repository.per_title')]}
                                                    selected={order}
                                                    onClick={(element) => { setOrder(element) }}
                                                />
                                            </button>
                                        </div>
                                    </div>
                                    {/* END MOVE TO COMPONENT */}

                                    {listView ? <ProjectList
                                        list={data}
                                        perPage={resultsPerPage}
                                        currentPage={currentPage}
                                        onListViewChange={() => setListView(false)}
                                    /> :
                                        <div className='show-overflow-x'>
                                            <CustomTable className='bg-white mb-4' data={structuredClone(data.map(x => {
                                                return {
                                                    "ID": x.id,
                                                    "Titolo": x.title,
                                                    "Abstract": x.abstract,
                                                    "Data": x.date,
                                                    'Contributo': x.contributo,
                                                    'Investimenti regionali': x.regionalInvestment,
                                                    'Sistemi': x.sistemi,
                                                    'Settori': x.settori,
                                                    'Tipo': x.type,
                                                    'Programma': x.programma,
                                                    'Azioni': x.azioni.map(x => x.name).join(', '),
                                                    'Beneficiari': x.beneficiari.map(x => x.name).join(', '),
                                                    'Link': '/progetto?id=' + x.id
                                                }
                                            })).slice((currentPage - 1) * resultsPerPage, currentPage * resultsPerPage)} />
                                        </div>
                                    }

                                    <Pagination
                                        position='right'
                                        total={Math.ceil(data.length / resultsPerPage)}
                                        onChange={(page) => {
                                            console.log('page changed to', page);
                                            setCurrentPage(page);
                                            window.scrollTo(0, 0);
                                        }}
                                        styles={(theme) => ({
                                            item: {
                                                color: 'black',
                                                '&[data-active]': {
                                                    backgroundColor: 'var(--main-color)',
                                                }
                                            }
                                        })}
                                    />
                                </div>
                                : <div className='text-center'>
                                    <h3 className='text-center'>{t('common.no_results')}</h3>
                                    <p className='text-center'>{t('common.research_no_results')}</p>
                                    <button
                                        className='btn border-0 opacity-7' onClick={async () => {
                                            await Promise.all([
                                                setKeywords([]),
                                                setSistemiFilter([]),
                                                setAmbitiFilter([]),
                                                setAzioniFilter([]),
                                                setProgrammiFilter([]),
                                                setProvinceFilter([]),
                                                setBeneficiariFilter([]),
                                                setTipiAzioneFilter([]),
                                                setTipiBeneficiarioFilter([])
                                            ])
                                        }}><i className='fas fa-sm fa-times-circle me-2' />{t('common.reset_filters')}</button>

                                </div>}
                        </div>}
                    </div>
                </div>
            </div>

        </div>
    );
};

export default Repository;
