import React, { useEffect, useState } from 'react';
import Divider from '../../Common/Divider';
// import './index.css'
import Modale from '../../Common/Modale';
import Loader from '../../Common/Loader';
import { NavLink } from 'react-router-dom';

import ArrowRight from '@iconscout/react-unicons/icons/uil-arrow-circle-right'
import LineChart from '../../Charts/LineChart';
import WordCloud from '../../Charts/WordCloud';
import CustomTable from '../../Common/Table';

import { settoriTexts, colorsMap, formatWithSeparators } from '../../../data/strings';
import SingleAreaModal from '../SingleAreaModal';
import Switch from '../../Common/Switch';
import Chord from '../../Charts/Chord';
import Bubble from '../../Charts/Bubble';
import BarChart from '../../Charts/BarChart';
import Sankey from '../../Charts/Sankey';
import Heatmap from '../../Charts/Heatmap';
import Table from '../../Common/Table';
import { Tabs, Accordion } from '@mantine/core';
import './index.css'
import _ from 'lodash';
import {
    getAmbiti,
    getAreas,
    getAreasConnections,
    getAmbitiConnections,
    getSistemiAmbitiConnections,
    getNProjects,
    getNProjectsPerYear,
    getFunding,
    getContribution,
    getCollaboratori,
    getKeywords
} from '../../../data/dataService';

import { useTranslation } from 'react-i18next';

const PartecipanteModal = ({ title, show, partecipanteObj, onChange, color }) => {

    const { t, i18n } = useTranslation()

    const [singleModalConfig, setSingleModalConfig] = useState({});
    const [sistemiShowingChart, setSistemiShowingChart] = useState('chord');
    const [showSingleAreaModal, setShowSingleAreaModal] = useState(false);
    const [ambitiShowingChart, setAmbitiShowingChart] = useState('bubble');

    const [partecipante, setPartecipante] = useState(null);
    const [loading, setLoading] = useState(true);


    function getSingleModalConfig(d, perimeter) {
        if (!partecipante) return;
        if (!perimeter)
            if (partecipante.sistemi[d])
                perimeter = 'sistemi';
            else if (partecipante.ambiti[d])
                perimeter = 'ambiti';
            else return

        var ds = partecipante.sistemi, dsConnections = partecipante.sistemiConnections;
        if (perimeter == 'sistemi') {
            ds = partecipante.sistemi;
            dsConnections = partecipante.sistemiAmbitiConnections;
        }
        else if (perimeter == 'ambiti') {
            ds = partecipante.ambiti;
            dsConnections = partecipante.ambitiConnections;
        }

        return {
            title: d,
            color: colorsMap[d] ? colorsMap[d] : "#657F81",
            content: settoriTexts[d] ? settoriTexts[d] : 'Descrizione non disponibile',
            keywords: [],
            totalProjects: ds[d] && ds[d].nProjects ? ds[d].nProjects : 'N.A.',
            commonProjects: _.uniqBy(dsConnections.filter((item) => item.source === d || item.target === d).map((item) => {
                return {
                    name: item.source === d ? item.target : item.source,
                    projects: item.value
                }
            }), 'name')
        }
    }

    const [bubbleChartData, setBubbleChartData] = useState([]);
    const [ambitiBarChartData, setAmbitiBarChartData] = useState({});
    const [ambitiTableData, setAmbitiTableData] = useState([]);
    const [sistemiBarChartData, setSistemiBarChartData] = useState({});
    const [sistemiTableData, setSistemiTableData] = useState([]);


    function organiseDataForCharts(partecipante) {

        if (!partecipante) return;
        // Iterate ambiti and create data for bubble chart
        let bubbleChartData = []
        for (const [ambito, value] of Object.entries(partecipante.ambiti)) {
            if (ambito != "Altro" && ambito != "Other") {
                bubbleChartData.push({
                    name: ambito,
                    value: value.nProjects,
                    link: partecipante.ambitiConnections.filter((item) => item.source === ambito || item.target === ambito).map((item) => {
                        return item.source === ambito ? item.target : item.source
                    })
                })

                ambitiBarChartData[ambito] = value.nProjects
                ambitiTableData.push({
                    "Ambito cross-settoriale": ambito,
                    "Progetti": value.nProjects,
                    // "Finanziamenti": value.funding,
                })
            }
        }

        setBubbleChartData(bubbleChartData);
        setAmbitiBarChartData(ambitiBarChartData)
        setAmbitiTableData(ambitiTableData)


        // Iterate sistemi and create data for bar chart and table
        let sistemiBarChartData = {}
        let sistemiTableData = []
        for (const [sistema, value] of Object.entries(partecipante.sistemi)) {
            if (sistema != "Altro" && sistema != "Other") {
                sistemiBarChartData[sistema] = value.nProjects

                sistemiTableData.push({
                    "Sistema di specializzazione": sistema,
                    "Progetti": value.nProjects,
                    // "Finanziamenti": value.funding,
                })
            }
        }
        setSistemiBarChartData(sistemiBarChartData)
        setSistemiTableData(sistemiTableData)

        setLoading(false);
    }



    const loadData = async () => {
        let fromYear = 2014;
        let toYear = new Date().getFullYear();
        console.log('PartecipanteModal: useEffect');

        var filtersObject = {
            beneficiari: [partecipanteObj.name],
        };

        const nProjectsPromise = getNProjects([fromYear, toYear], false, filtersObject);
        const nProjectsPerYearPromise = getNProjectsPerYear([fromYear, toYear], false, filtersObject);
        const fundingPromise = getFunding([fromYear, toYear], false, filtersObject);
        const contributionPromise = getContribution([fromYear, toYear], false, filtersObject);
        const sistemiPromise = getAreas([fromYear, toYear], false, filtersObject);
        const ambitiPromise = getAmbiti([fromYear, toYear], false, filtersObject);
        const sistemiConnectionsPromise = getAreasConnections([fromYear, toYear], false, filtersObject);
        const ambitiConnectionsPromise = getAmbitiConnections([fromYear, toYear], false, filtersObject);
        const sistemiAmbitiConnectionsPromise = getSistemiAmbitiConnections([fromYear, toYear], false, filtersObject);
        const collaboratorsPromise = getCollaboratori(partecipanteObj.name, false);
        const regionalCollaboratorsPromise = getCollaboratori(partecipanteObj.name, true);
        const keywordsPromise = getKeywords([fromYear, toYear], filtersObject, 50);

        const [nProjects, nProjectsPerYear, funding, contribution,
            sistemi, ambiti, sistemiConnections, ambitiConnections, sistemiAmbitiConnections,
            collaborators, regionalCollaborators,
            keywords
        ] = await Promise.all([
            nProjectsPromise, nProjectsPerYearPromise, fundingPromise, contributionPromise,
            sistemiPromise, ambitiPromise, sistemiConnectionsPromise, ambitiConnectionsPromise, sistemiAmbitiConnectionsPromise,
            collaboratorsPromise, regionalCollaboratorsPromise,
            keywordsPromise
        ]);

        var newPartecipanteObj = {
            id: partecipanteObj.name,
            name: partecipanteObj.name,
            luogo: partecipanteObj.province,
            sistemi: sistemi,
            ambiti: ambiti,
            nProjects: nProjects,
            nProjectsPerYear: nProjectsPerYear,
            funding: funding,
            contribution: contribution,
            sistemiConnections: sistemiConnections,
            ambitiConnections: ambitiConnections,
            sistemiAmbitiConnections: sistemiAmbitiConnections,
            regionalCollaborators: regionalCollaborators.map((item) => {
                return {
                    "Collaborazione": item.collabName,
                    'Provincia': item.collabProvince,
                    'N° progetti': item.nProjects,
                }
            }).sort((a, b) => b['N° progetti'] - a['N° progetti']),
            collaborators: collaborators.filter(x => !regionalCollaborators.map(x => x.collabProvince).includes(x.collabProvince)).map((item) => {
                return {
                    "Collaborazione": item.collabName,
                    'Provincia': item.collabProvince,
                    'N° progetti': item.nProjects,
                }
            }).sort((a, b) => b['N° progetti'] - a['N° progetti']),
            keywords: keywords.map((item) => {
                return {
                    text: item.kw,
                    value: item.n,
                }
            })

        }

        console.log(newPartecipanteObj)

        setPartecipante(newPartecipanteObj)
        organiseDataForCharts(newPartecipanteObj);
    }

    useEffect(() => {
        loadData();
    }, []);

    function formatM(n) {
        // return (n / 1000000).toFixed(2).replace(/\.0$/, '') + 'M';
        return formatWithSeparators(n / 1000000, 2) + "M"
    }

    return (
        <Modale
            show={show}
            onChange={onChange}
            title={partecipante ? (partecipante.name) : t('repository.scheda_progetto.scheda_partecipante')}
            color={'var(--main-color)'}
            size='90%'
        >

            <SingleAreaModal
                show={showSingleAreaModal}
                onChange={() => setShowSingleAreaModal(!showSingleAreaModal)}
                title={singleModalConfig.title}
                color={singleModalConfig.color}
                content={singleModalConfig.content}
                totalProjects={singleModalConfig.totalProjects}
                commonProjects={singleModalConfig.commonProjects}
                keywords={singleModalConfig.keywords}
            />

            <div className='container-fluid text-center'>
                {!loading && partecipante ?
                    partecipante.nProjects == 0 ? <div className='p-4'>
                        <p className='h2'>{t('repository.scheda_partecipante.non_disponibile')}</p>
                    </div> : <div>
                        <div className='d-flex flex-lg-row flex-column gap-5 '>
                            <div className='partecipante-name'>
                                <h2 className='fw-normal text-start mb-0 pb-0'>{partecipante.name}</h2>
                                <h3 className='h5 text-start mt-0 pt-0'>{partecipante.luogo}</h3>
                            </div>
                            <div className='d-flex'>
                                <div className=''>
                                    <h2 className='fw-normal text-start mb-0 pb-0 font-monospace'>{partecipante.nProjects}</h2>
                                    <p className='h5 text-start mt-0 pt-0'>
                                        {t('repository.scheda_partecipante.progetti_finanziati')}
                                    </p>
                                </div>
                                <div className=''>
                                    <h2 className='fw-normal text-start mb-0 pb-0 font-monospace'>{formatM(partecipante.funding)}€</h2>
                                    <p className='h5 text-start mt-0 pt-0'>
                                        {t('repository.scheda_partecipante.finanziamenti_ricevuti')}
                                    </p>
                                </div>
                                <div className=''>
                                    <h2 className='fw-normal text-start mb-0 pb-0 font-monospace'>{formatM(partecipante.contribution)}€</h2>
                                    <p className='h5 text-start mt-0 pt-0'>
                                        {t('repository.scheda_partecipante.contributi_ricevuti')}
                                    </p>
                                </div>
                            </div>
                            {/* <div className='my-auto d-flex'>
                                <NavLink
                                    style={{ textDecoration: 'none' }}
                                    to={`/repository?participantId=${partecipante.name}`} className='text-start d-flex'><h5 className='my-auto'>Visualizza {partecipante.nProjects} progetti</h5><ArrowRight size={25} className='my-auto p-0 text-dark' /></NavLink>
                            </div> */}
                        </div>
                        <div className='row mt-5'>
                            <div className='col-md-6 col-12'>
                                <h2 className='h4 text-start'>
                                    {t('repository.scheda_partecipante.evoluzione')}
                                </h2>
                                <div className='mt-4'>
                                    <LineChart type='projects' height={250} data={partecipante.nProjectsPerYear.filter(x => x.year >= 2014).map(x => {
                                        return { x: x.year, y: x.value }
                                    })} />

                                </div>
                            </div>
                            {partecipante.keywords && partecipante.keywords.length > 10 ? <div className='col-md-6 col-12'>
                                <h2 className='h4 text-start'>
                                    {t('repository.scheda_partecipante.keywords_principali')}
                                </h2>
                                <div className='mt-4'>
                                    <WordCloud height={250} keywords={partecipante.keywords} />
                                </div>
                            </div> : null}
                        </div>
                        <div className='row mt-3'>
                            <div className='col-md-6 col-12 '>
                                <h2 className='h4 text-start'>
                                    {t('repository.scheda_partecipante.distribuzione_sistemi')}
                                </h2>
                                <div className='col-12 text-center'>
                                    <Switch
                                        className='mt-2'
                                        onChange={function (value) { setSistemiShowingChart(value) }}
                                        values={[{ name: 'chord', icon: <i className='fas px-2 py-1 fa-line-chart' /> },
                                        { name: 'bar', icon: <i className='fas px-2 py-1 fa-bar-chart' /> },
                                        { name: 'table', icon: <i className='fas px-2 py-1 fa-list' /> },
                                        ]}
                                    />

                                    {sistemiShowingChart === 'chord' && partecipante.sistemiConnections ?
                                        <div className='mt-3'>
                                            <Chord
                                                id={'chord-' + partecipante.id}
                                                onNodeClick={(d) => {
                                                    console.log(d)
                                                    setShowSingleAreaModal(true);
                                                    setSingleModalConfig(getSingleModalConfig(d, 'sistemi'))
                                                }}
                                                data={partecipante.sistemiConnections}
                                            />
                                        </div> : <></>}
                                    {sistemiShowingChart === 'bar' && sistemiBarChartData ?
                                        <div className='mt-3'>
                                            <BarChart
                                                onBarClick={(d) => {
                                                    setShowSingleAreaModal(true);
                                                    setSingleModalConfig(getSingleModalConfig(d, 'sistemi'))
                                                }}
                                                data={sistemiBarChartData} />
                                        </div> : <></>}
                                    {sistemiShowingChart === 'table' && sistemiTableData ?
                                        <div className='mt-3 responsive-table'>
                                            <Table
                                                data={sistemiTableData} />
                                        </div> : <></>}
                                </div>
                            </div>
                            <div className='col-md-6 col-12'>
                                <h2 className='h4 ext-start'>
                                    {t('repository.scheda_partecipante.distribuzione_ambiti')}</h2>
                                <div className='col-12  text-center'>
                                    <Switch
                                        className='mt-2'
                                        onChange={function (value) { setAmbitiShowingChart(value) }}
                                        values={[{ name: 'bubble', icon: <i className='fas px-2 py-1 fa-line-chart' /> },
                                        { name: 'bar', icon: <i className='fas px-2 py-1 fa-bar-chart' /> },
                                        { name: 'table', icon: <i className='fas px-2 py-1 fa-list' /> },
                                        ]}
                                    />

                                    {ambitiShowingChart === 'bubble' && bubbleChartData ?
                                        <div className='mt-3'>
                                            <Bubble
                                                id={'bubble-' + partecipante.id}
                                                onBubbleClick={(d) => {
                                                    setShowSingleAreaModal(true);
                                                    setSingleModalConfig(getSingleModalConfig(d, 'ambiti'))
                                                }}
                                                data={bubbleChartData} />
                                        </div> : <></>}

                                    {ambitiShowingChart === 'bar' && ambitiBarChartData ?
                                        <div className='mt-3'>
                                            <BarChart
                                                height={700}
                                                onBarClick={(d) => {
                                                    setShowSingleAreaModal(true);
                                                    setSingleModalConfig(getSingleModalConfig(d, 'ambiti'))
                                                }}
                                                data={ambitiBarChartData} />
                                        </div> : <></>}
                                    {ambitiShowingChart === 'table' && ambitiTableData ?
                                        <div className='mt-3 responsive-table'>
                                            <Table
                                                data={ambitiTableData} />
                                        </div> : <></>}

                                </div>
                            </div>
                        </div>
                        <div className='row mt-3 pb-3'>
                            {partecipante.regionalCollaborators ? <div className='col-md-6 col-12 collaborators-table '>
                                <h2 className='h4 text-start collaboratori-title'>{t('repository.scheda_partecipante.collaborators_region')}</h2>
                                <CustomTable
                                    data={partecipante.regionalCollaborators}
                                />
                            </div> : <></>}
                            {partecipante.collaborators ? <div className='col-md-6 col-12 collaborators-table'>
                                <h2 className='h4 text-start collaboratori-title'>{t('repository.scheda_partecipante.collaborators_external')}</h2>
                                <CustomTable
                                    data={partecipante.collaborators}
                                />
                            </div> : <></>}
                        </div>
                    </div> : <Loader className='mx-auto' size={50} />}
            </div>
        </Modale>
    );
};

export default PartecipanteModal;